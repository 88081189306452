import {_get, _post} from "@api/doinsport/services/httpService";
import { URL_CLIENT } from "../index";

export const URI = URL_CLIENT + '/wallets';

const URI_OPERATIONS = URI + '/operations'

export const getClientWallet = (clientId) => _get(URI + '?client.id=' + clientId);

export const postClientWallet = (data) => _post(URI, data);

export const getCustomerWalletHistory = (walletId, page, totalRows) => _get(
    URI_OPERATIONS +
    '?wallet.id=' + walletId +
    '&page='+ page +
    '&itemsPerPage=' + totalRows
)
