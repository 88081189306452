<template>
  <div>
    <d-body-top-bar :title="$t('views.wallet-refills.title')">
      <div slot="tree">
        <label class="inactive-tree">
          {{ $t('data.menu.clients.title') }} /
          <label class="inactive-tree link-area pointer"
                 @click="$router.push({ name: 'customer_details', params: { id: $route.params.id} })">
            {{ name ? name : $t('views.client.details.body.left.subscription-affect.profile') }} /
          </label>
        </label>
        <label class="bg-doinsport-primary ml-1"> {{ $t('views.wallet-refills.add-wallet.title') }}</label>
      </div>
      <div slot="icons">
      </div>
    </d-body-top-bar>
    <div class="container-fluid">
      <b-card
        :class="innerWidth ? '' : 'p-3'"
      >
        <validation-observer
          ref="creditsPackAffectObserver"
          slim
        >
          <b-row>
            <b-col :class="widthCheck (1100) ? 'col-divider-5': ''" :cols="widthCheck (1100) ? '' : 12">
              <wallet-refill-selection
                class="mt-3"
                :selected-wallet-refill="selectedWalletRefill"
              />
            </b-col>
            <div v-if="widthCheck (1100)" class="split-layout__divider">
              <div class="split-layout__rule"></div>
              <div class="split-layout__rule"></div>
            </div>
            <b-col :cols="widthCheck (1100) ? 6 : 12">
              <wallet-refill-resume
                class="mt-3"
                :selected-wallet-refill="selectedWalletRefill"
              />
            </b-col>
          </b-row>
        </validation-observer>
        <b-row>
          <b-col align="right">
            <d-button
              :class="[selectedWalletRefill.selected ? '' : 'disabled', innerWidth ? '' : ' custom-customer-button mt-4 mb-3']"
              text="general.actions.validate"
              class="d-btn-sm font-text-title btn d-btn-danger font-text-title"
              @on:button-click="onValidate"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>
<script>
import {getClubClient} from "@api/doinsport/services/client/client.api";
import Client from "@/classes/doinsport/Client";
import {postClientWallet} from "@api/doinsport/services/client/wallet/wallet.api";
import {fromIdToIriReference} from "@/utils/form";
import {postWalletOperation} from "@api/doinsport/services/client/wallet/operation/operation.api";
import {SUCCESS} from "@plugins/flash";

export default {
  data: () => ({
    customer: null,
    selectedWalletRefill: {newSold: 0, values: null, selected: false},
  }),
  components: {
    WalletRefillSelection: () => import('./WalletRefillSelection'),
    WalletRefillResume: () => import('./WalletRefillResume')
  },
  computed: {
    innerWidth(){
      return this.$store.getters['layout/getInnerWidth'] > 900;
    },
    newSold () {
      const amount = this.selectedWalletRefill.values.amount.split(this.currency)[0];
      const currentSold = this.customer.wallet ? this.customer.wallet.balance : 0 ;
      const total = parseFloat(amount) + parseFloat(currentSold) / 100;

      return total.toFixed(2);
    },
    name() {
      if (this.customer) {
        return this.customer.fullName;
      }
      return null;
    },
  },
  methods: {
    onValidate() {
      if (this.selectedWalletRefill.selected) {
        let balance = null;

        if (this.customer.wallet === null) {
          balance = this.newSold;
        } else {
          balance = (this.newSold - (parseFloat(this.customer.wallet.balance) / 100)).toFixed(2);
        }
        let toPost = null;

        if (this.customer.wallet === null) {
          toPost = { label: this.selectedWalletRefill.values.name, overdraftAuthorized: 10};
        } else {
          toPost = {label: this.selectedWalletRefill.values.name, wallet: this.customer.wallet['@id'], overdraftAuthorized: 10};
        }

        if (balance > 0) {
          Object.assign(toPost, {credit: parseInt((balance * 100).toFixed(2))});
        } else {
          Object.assign(toPost, {debit: parseInt((Math.abs(balance) * 100).toFixed(2))});
        }
        if (this.customer.wallet === null) {
          postClientWallet({
            client: fromIdToIriReference('/clubs/clients', this.$route.params.id),
            operations: [toPost]
          })
            .then((response) => {
              this.$flash(null, this.$t('general.actions.success-update'), 3000, SUCCESS);

              this.$router.replace({name: 'customer_details', params: {id: this.$route.params.id}})
            })
          ;
        } else {
          postWalletOperation(toPost)
            .then((response) => {
              this.$flash(null, this.$t('general.actions.success-update'), 3000, SUCCESS);

              this.$router.replace({name: 'customer_details', params: {id: this.$route.params.id}})
            })
          ;
        }
      }
    },
    widthCheck(check) {
      return this.$store.getters['layout/getInnerWidth'] > check;
    },
    loadCustomer() {
      getClubClient(this.$route.params.id)
        .then((response) => {
          this.customer = new Client(response.data, {serialize: true});
        })
      ;
    }
  }
  ,
  created() {
    this.loadCustomer();
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/customer/details/_add-wallet-index.scss";
@import "@lazy/_custom-buttom-mobile.scss";
@import "@lazy/_b-card.scss";
</style>
