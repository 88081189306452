import {hydrate} from "@/utils/form";

export default class Client {
  addresses = [];
  advertisingEnabled = true;
  birthDate = null;
  cashRegisterId = null;
  club = null;
  createdAt = null;
  documents = [];
  familyGroup = null;
  email = null;
  isLocked = null;
  lock = null;
  firstName = null;
  description = null;
  accessCode = null;
  affiliated = false;
  licenceNumber = null;
  formattedNumber = null;
  gender = null;
  medicalCertificate = false;
  groups = [];
  id = null;
  identityPhoto = null;
  stripeCustomerReference = null;
  nextoreCustomerReference = null;
  lastName = null;
  phoneNumber = null;
  updatedAt = null;
  wallet = null;
  company = null;
  externalContactId = null;
  subscriptionCardsAvailable = [];
  user = null;
  fullName = null;

  constructor(object, options) {
    if ("undefined" !== typeof options) {
      if (options.serialize) {
        this.serialize(object);
      }
    }
  }

  serialize(object) {
    hydrate(this, object);
  }
}
